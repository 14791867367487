* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Baloo 2', sans-serif;
}

:root {
  --primary: #FFFAF2;
  --primary2: #00FF7A;
  --primary-text: #1a1a1a;
  --secondary: #FFF5E4;
  --secondary-text: #a71b1b;
  --accent: #e4ffea;
  --accent-text: #0a0909;
}
